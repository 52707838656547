import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Col, Row } from 'react-bootstrap';
import { WWCharacter } from '../../../modules/ww/common/components/ww-character';
import { faTrophy, faCookieBite } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const WWGuidesRer: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ww-guide'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_reroll.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Reroll guide</h1>
          <h2>
            How to reroll in Wuthering Waves and who is the best character to go
            for!
          </h2>
          <p>
            Last updated: <strong>24/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Is it even worth to reroll?" />
        <p>
          The short answers is <strong>no</strong>.
        </p>
        <p>And here's the long answer:</p>
        <ul>
          <li>
            Wuthering Waves doesn't have a 'Guest Login' option. This means you
            will need to create a new account for every reroll attempt,
          </li>
          <ul>
            <li>
              <strong>Salting works, but only the . method.</strong>. So you can
              input a . into your email in various places to reuse it,
            </li>
            <li>
              For example if your email is myemail@gmail.com, you can create an
              account with m.yemail@gmail.com and my.email@gmail.com and they
              will be treated by the account system as different emails,
            </li>
          </ul>
          <li>
            It takes around <strong>25-35 minutes</strong> to finish the
            tutorial and reach the moment when you can actually start pulling,
          </li>
          <li>
            The rates in the game are{' '}
            <strong>pretty low (0.8% for a 5★ Character)</strong>, so it might
            take you even a whole day of rolling to get one,
          </li>
          <li>
            <strong>
              You are guaranteed to start the game with three 5★ Characters:
            </strong>
          </li>
          <ul>
            <li>
              One from the <strong>Voucher of Reciprocal Tides</strong> via mail
              (available from 26.05.2024 to 22.05.2025),
            </li>
            <li>
              One from the <strong>Novice Convene</strong> (within 50 pulls),
            </li>
            <li>
              One from the <strong>Selector Convene</strong> (within 80 pulls).
            </li>
          </ul>
        </ul>
        <p>If you're still here, then I think this meme is perfect for you:</p>
        <StaticImage
          src="../../../images/ww/generic/reroll_pain.webp"
          alt="Reroll Pain"
        />
        <p>So let's continue with the reroll guide!</p>
        <SectionHeader title="How to reroll" />
        <h5>Reroll methods</h5>
        <p>
          Sadly, there's just one. As we mentioned in the warning section, you
          will need to prepare a ton of dummy emails (or use them with limited
          salting):
        </p>
        <ol>
          <li>Log in to the game with new mail,</li>
          <li>
            Suffer for 25-35 minutes through the tutorial and early story till
            you unlock Convene,
          </li>
          <ul>
            <li>
              The time can be reduced once the skip is added to prologue story,
              but we have no ETA on it yet,
            </li>
          </ul>
          <li>Then suffer even more as you're doing your pulls,</li>
          <li>Log out and log in with new email.</li>
        </ol>
        <p>
          Now let's move one to what exactly you need to do during the reroll.
        </p>
        <SectionHeader title="The prologue" />
        <StaticImage src="../../../images/ww/generic/tut.webp" alt="Prologue" />
        <p>
          The prologue act as tutorial in Wuthering Waves and as we mentioned
          earlier,{' '}
          <strong>
            it takes around 35-45 minutes to reach the part of it that unlocks
            Convene
          </strong>
          . You just have to follow the main quest,{' '}
          <strong>so don't be tempted to explore the map</strong>! This will
          come later and first you should focus on the main path only.
        </p>
        <h5>Prologue steps (beware, spoilers!)</h5>
        <ol>
          <li>
            You wake up in a strange place, not knowing how you got there. You
            will meet Yangyang and Chixia soon after,
          </li>
          <li>Chase after them and Yangyang will join your team,</li>
          <li>
            After meeting Baizhi, you will fight with the Crownless - this will
            be your first true combat test,
          </li>
          <li>
            Once Crownless is defeated you will have a short talk with Jinhsi
            projection. Your next goal is reaching the town of Jinzhou.{' '}
            <strong>
              While hunting monsters for their Echoes will be tempting, ignore
              it for now and just focus on reaching the town,
            </strong>
          </li>
          <li>
            When you enter Jinzhou, Chixia will join your team! Continue the
            main quest and head to the City Hall to meet Sanhua. From here, you
            will need to find the Huaxu Academy,
          </li>
          <li>
            After talking with Mortefi at the academy, you will do your tutorial
            pull and obtain <strong>Baizhi</strong>. Now, grab the pre-register
            rewards from the mail and start pulling!
          </li>
        </ol>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Reroll paths" />
        <p>List of currently available resources for reroll:</p>
        <ul>
          <li>
            40x <strong>Lustrous Tides</strong>:
          </li>
          <ul>
            <li>
              20x <strong>Lustrous Tides</strong> from the pre-register rewards
              (expiration unknown),
            </li>
            <li>
              10x <strong>Lustrous Tides</strong> from the special compensation
              (expiration unknown),
            </li>
          </ul>
          <li>
            <strong>Voucher of Reciprocal Tides (5★)</strong> from the special
            compensation (expires on 22.05.2025).
          </li>
        </ul>
        <p>
          Now, you will have to choose two paths for the reroll, depending on
          how much free time you have and how much sanity are you willing to
          lose.
        </p>
        <Row xs={1} xl={2} xxl={2} className="reroll-paths">
          <Col>
            <div className="box">
              <div className="option hard">
                <p>Hell Path</p>
              </div>
              <div className="inside">
                <p className="time">
                  Difficulty: <strong>HELL</strong>
                </p>
                <p>
                  In this reroll path, you will aim to pull the character from
                  the rate-up banner using the Radiant Tides and whatever amount
                  of Astrites you will manage to find before unlocking the
                  banner (enough for 1-2 more pulls most likely).
                </p>
                <hr />
                <h6>Process</h6>
                <ul>
                  <li>
                    Play through the tutorial according to the guide above to
                    unlock Convene feature,
                  </li>
                  <li>
                    Do the 1-2 pulls on the <strong>rate-up banner</strong>,
                  </li>
                  <li>
                    Do the 30 pulls on the{' '}
                    <strong>Character Permanent Convene</strong> and aim to get
                    another 5★ character (optional).
                  </li>
                </ul>
                <p>
                  If you got the rate-up, congrats! Continue playing the account
                  and pull on the Novice Convene and then Selector Convene which
                  will give you two additional 5★ (one random and one of your
                  choice). Only use your Voucher of Reciprocal Tides after
                  obtain the Novice Convene, so you don't get a dupe.
                </p>
              </div>
            </div>
          </Col>
          <Col>
            <div className="box">
              <div className="option easy">
                <p>Heaven Path</p>
              </div>
              <div className="inside">
                <p className="time">
                  Difficulty: <strong>Medium</strong>
                </p>
                <p>
                  This path ignores the rate-up banner and instead focuses on
                  getting 4x 5★ Characters - one from lucky pull and the other
                  two from the guaranteed banners.
                </p>
                <hr />
                <h6>Process</h6>
                <ul>
                  <li>
                    Play through the tutorial according to the guide above to
                    unlock Convene feature,
                  </li>
                  <li>
                    Do 30 pulls on the{' '}
                    <strong>Character Permanent Convene</strong> right after
                    unlocking summoning. Your aim is to start with 4x 5★
                    Characters. Not from the reroll itself, but overall - reroll
                    goal is 1 or more characters from the Permanent Convene.
                  </li>
                  <ul>
                    <li>
                      First character - from the 30 pulls done on Character
                      Permanent Convene (remember - 0.8% chance),
                    </li>
                    <li>
                      Second character - from the Novice Convene pity within 50
                      pulls,
                    </li>
                    <li>
                      Third character - from the Selector Convene within 80
                      pulls,
                    </li>
                    <li>
                      Fourth character - from the Standard Special Selection
                      Ticket.
                    </li>
                  </ul>
                  <li>
                    Do the available pulls on the{' '}
                    <strong>current rate-u[] banner</strong> and maybe you will
                    get lucky!
                  </li>
                </ul>
                <p>
                  If you got a character during the initial 30 pulls and it was
                  someone you wanted, congrats! The reroll is over and you
                  continue playing the game. If now, find another email and
                  start again. Only use your{' '}
                  <strong>Voucher of Reciprocal Tides</strong> after obtain the
                  Novice Convene, so you don't get a dupe.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <h5>What about Weapons?</h5>
        <p>
          <strong>Summoning for Weapons isn't advised</strong> as you can do
          well with the lower rarity ones while a character will give your
          account more depth and flexibility. Especially that you will need
          multiple teams to tackle the harder content later down the line and
          also once you reach Union Level 45, you will receive a 5★ standard
          weapon selector.
        </p>
        <SectionHeader title="Video" />
        <p>
          You can get some additional pulls from various shops and Volkin's
          showcases that in his video here:
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="uW2RTscvVqk" />
          </Col>
        </Row>
        <SectionHeader title="5★ Characters" />
        <p>
          Here's the list of possible 5★ Characters you can pull from the Novice
          and Permanent Convene:
        </p>
        <div className="employee-container for-nikke">
          <WWCharacter mode="icon" slug="verina" enablePopover showLabel />
          <WWCharacter mode="icon" slug="encore" enablePopover showLabel />
          <WWCharacter mode="icon" slug="calcharo" enablePopover showLabel />
          <WWCharacter mode="icon" slug="lingyang" enablePopover showLabel />
          <WWCharacter mode="icon" slug="jianxin" enablePopover showLabel />
        </div>
        <p>
          Now which of the 5 available characters is the best one? Let's go over
          each of them to show their good and bad sides!{' '}
          <strong>
            For more information about each of the characters, please visit
            their profiles
          </strong>{' '}
          (using the icons above) - below we will only post a short summary.
        </p>
        <div className="reroll-char-info reroll first">
          <div className="top Spectro">
            <p className="name">Verina [Support]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <WWCharacter
                  mode="icon"
                  slug="verina"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Verina is the strongest Support character on release, offering
                universal buffs, healing, and the ability to prevent a death on
                your team with a Cheat Death mechanic. Most endgame teams on
                release consist of a Main DPS, a Hybrid character, and Support.
                The 1.0 patch has very few Supports available and the available
                ones have limited capabilities or are more awkward to play
                compared to Verina. As an example let's compare Baizhi and
                Verina. Verina has bigger buffs that buff the whole team
                compared to Baizhi’s smaller individual and conditional buffs,
                Verina is easier to play and also requires less field time
                meaning more time for your damage dealers.
              </p>
              <p>
                Overall, Verina is the best in slot support for every top-tier
                team composition in the endgame on release and with you needing
                3 teams for the endgame she’ll be unlikely to be hitting the
                bench any time soon.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>Strong team-wide heals and buffs,</li>
                <li>
                  One of the best Outros in the game, granting every unit 15%
                  DMG Deepen,
                </li>
                <li>
                  Can effortlessly enable the 5P Healer set bonus without
                  sacrificing her weapon choice,{' '}
                </li>
                <li>Barely takes any on-field time,</li>
                <li>Easy to use,</li>
                <li>Cheat Death mechanic.</li>
              </ul>
              <ul className="cons">
                <li>Her design might not be to everyone's taste.</li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon icon={faTrophy} width="30" className="gold" />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Fusion">
            <p className="name">Encore [Main DPS]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <WWCharacter
                  mode="icon"
                  slug="encore"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Encore is a ranged main damage dealer who transforms into a
                melee powerhouse during her self-empowerment Ultimate. Encore
                has huge damage potential while her ultimate is active thanks to
                it empowering her combos making her a reasonable Hyper Carry
                target. But she can also be played as a Quick Swap powerhouse
                thanks to her easily swap-cancellable Resonance Skill and Forte
                Gauge Heavy Attacks. In this style, her Ultimate can be used to
                fill downtime during the teams' cooldowns making her a valuable
                addition.
              </p>
              <p>
                Executing Encore's ultimate combo is easy, straightforward, and
                forgiving thanks to its damage being mostly spread out between
                each attack and possessing a semi-ranged component making it
                easy to land on enemies even at some distance.
              </p>
              <p>
                In conclusion, Encore does exceptional damage during her burst
                windows whether that be her ultimate in Hyper Carry or her swap
                canceled resonance skills in quick swap - or a mix of both. She
                is a top-tier choice for the primary endgame mode - Tower of
                Adversity.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>
                  A mix of ranged and melee in one character, offering good
                  flexibility but may be a curse to some,
                </li>
                <li>Outstanding burst damage in both her modes of play</li>
                <li>
                  Great Resonance energy generation for herself but also her
                  team,
                </li>
                <li>
                  Ultimate burst rotation and resonance skill swap cancel are
                  easy to understand and execute
                </li>
                <li>Has a pet sheep.</li>
              </ul>
              <ul className="cons">
                <li>
                  Forte-charged heavy attacks have a small input window that can
                  leave Encore vulnerable,
                </li>
                <li>
                  Cannot parry while in non-ultimate form with standard attacks
                  making some specific bosses more challenging.
                </li>
                <li>
                  Fusion Echo Inferno Rider while powerful is lackluster unless
                  swap canceled. Other fusion Echoes outside this one are
                  underwhelming.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faTrophy}
                  width="30"
                  className="silver"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Aero">
            <p className="name">Jianxin [Hybrid]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <WWCharacter
                  mode="icon"
                  slug="jianxin"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Jianxin is a melee hybrid character that sits in between the
                support and damage dealer role. She offers the most diverse team
                benefits of any character currently on release which serves as
                both her biggest strength and weakness. Jianxin has it all, with
                her most defining features being her great sustain making her
                quite literally close to immune to all damage in overworld
                content with the exception of Holograms. On top of this, her
                huge Forte Gauge channels HUGE AoE making mopping up whole packs
                of weaker monsters easy.
              </p>
              <p>
                So Jianxin sounds incredible!? Well, there is a catch to Jianxin
                and that is that while she’s a jack of all trades and
                straight-up a one-woman-army in the overworld much of her kit
                requires both a lot of field time and dedicated channeling time
                both of which make her far more difficult to use in extremely
                challenging content.
              </p>
              <p>
                Despite Jianxia’s most defining ability being challenging to use
                in the hardest content, limiting her potential for damage and
                shielding, she can still thrive! Moving past her Forte, the
                Ultimate and Outro are valuable enough to position her as an
                incredible support with the former being the strongest grouping
                tool in the game and the latter empowering characters with
                substantial resonance Liberation damage in their damage
                profiles.
              </p>
              <p>
                Jianxin is one of the most comfortable characters to play in
                overworld when built as a Hybrid character and serves as one of
                the top options right now in the supportive role in the endgame
                when built as such.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>Loaded kit - can nearly do it all,</li>
                <li>
                  Operates as a one-woman-man army in the overworld thanks to
                  her kit having reasonable damage, crazy shields, and
                  self-healing,
                </li>
                <li>Can be played as a Support in ToA with great results,</li>
                <li>
                  Can gather monsters to quickly deal with them with great AoE
                  damage, which makes farming easier and makes some ToA stages
                  far easier,
                </li>
                <li>
                  Has an immunity parry mechanic on her skill which can allow
                  her to completely ignore certain boss mechanics,
                </li>
                <li>Simple to play especially as a Support.</li>
                <li>A Deepen outro capable of buffing Resonance Liberation.</li>
              </ul>
              <ul className="cons">
                <li>
                  Ultimate and Intro can't pull in boss or elite enemies making
                  her signature supportive benefit less valuable on some endgame
                  stages in ToA,
                </li>
                <li>
                  Enemies with large bodies like wolves that while can be
                  dragged will not clump up perfectly making AoE less effective,
                </li>
                <li>
                  Long animations that require her to remain stationary for up
                  to 6 seconds at a time which isn't always possible making a
                  portion of her kit hard to use best case and not usable worst
                  case in ultra endgame.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faTrophy}
                  width="30"
                  className="silver"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Aero">
            <p className="name">Calcharo [Main DPS]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <WWCharacter
                  mode="icon"
                  slug="calcharo"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Calcharo is an ultimate-centric Electro main damage dealer who
                focuses on executing some of the longest combo sequences in the
                game for exceptionally high damage. While Calcharo has
                incredibly high theoretical damage potential he isn’t the
                easiest character to play. The reason for this is his long combo
                sequences and reliance on his empowered ultimate state to deal
                the bulk of his damage - one or two mistakes can seriously
                impact the flow of his burst rotation leading to the loss of
                much of his potential.
              </p>
              <p>
                It is very important to note that Calcharo’s ultimate damage
                window is not easy at all to execute to the highest degree and
                even more so while trying to dodge boss mechanics and perform
                swap cancels between him and a teammate who can provide value in
                the time he is executing each Death Messenger. Failing to
                perfectly execute his full ultimate combo consisting of 3 Death
                Messengers will cost him a good chunk of his dps, resonance
                energy gain and concerto energy lowering his smoothness and
                total output.
              </p>
              <p>
                In conclusion, Calcharo is a combo-heavy character with a lot of
                room for skillful play to dish out some of the most damage of
                all characters when paired with Yinlin, but if you mess up his
                rotation, his damage will suffer. Calcharo can be a top-tier
                choice for the primary endgame mode Tower of Adversity but only
                if you can pilot him effectively.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>Exceptionally high damage when perfectly played,</li>
                <li>
                  Very active gameplay - there's always a button to press or
                  combo to perform (though this can be a downside for some),
                </li>
                <li>High-skill ceiling with huge rewards,</li>
                <li>
                  Great synergy with Yinlin, but he still has good team options
                  before she is released.
                </li>
              </ul>
              <ul className="cons">
                <li>Hard to play with all the combos and skill weaving,</li>
                <li>
                  Requires constant focus as there's very little room for error,
                </li>
                <li>
                  Long animations on most powerful moves (while they can be
                  'skipped' using the swap cancel trick, not everyone will be
                  able to abuse that),
                </li>
                <li>
                  When not swap canceling Death Messenger can be damaged and
                  even stunned during it.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faCookieBite}
                  width="30"
                  className="bronze"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Glacio">
            <p className="name">Lingyang [Main DPS]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <WWCharacter
                  mode="icon"
                  slug="lingyang"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Lingyang is a melee main damage dealer with one of the most
                unique play styles in the game which you’ll either love or
                absolutely hate. Similar to his peers - Calcharo and Encore -
                Lingyang deals the majority of his damage during his Ultimate
                which offers his an immense damage increase and accelerates his
                access to his special enhanced state that empowers all of his
                normal combos. But unlike the other two ultimate-focused
                standard characters, Lingyang spends the entirety of his
                ultimate in the air… yes Lingyang can fly for better or worse,
                meaning you’ll be attacking, dodging, and interacting with
                enemies in an entirely new dimension.
              </p>
              <p>
                Somewhat lower damage than his competitors but with a unique
                playstyle - so what makes Lingyang a less attractive pick as a
                main damage dealer? His flying is the reason - it's both his
                biggest blessing and hindrance. While airborne the control you
                have as a player over direction, orientation, and most
                importantly dodging is far more limited. Additionally, it’s very
                possible to encounter issues with boss and enemy hitboxes while
                airborne, causing you to miss entire attacks and combos leading
                to large damage losses. Lower control over both offense and
                defense will lead to more dangerous fights and less consistent
                damage, making Lingyang a challenging character to play for
                lesser or equal rewards compared to alternatives.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>Can fly!</li>
                <li>Unique playstyle not found anywhere else,</li>
                <li>
                  Gains the most free stats from his baseline S0 kit of any
                  character thanks to ultimate boost,
                </li>
                <li>
                  Button mashing friendly playstyle where you can’t really mess
                  up,
                </li>
                <li>
                  Works really well with Sanhua, a fellow Glacio character,
                </li>
                <li>
                  Can juggle non-elite or boss enemies in the air, comboing them
                  continuously and rendering them immobile.
                </li>
              </ul>
              <ul className="cons">
                <li>
                  Falls slightly behind other damage dealers such as Calcharo,
                  Jiyan, and Encore in the pure DPS department,
                </li>
                <li>Flying can be disorientating for some players,</li>
                <li>
                  Flying mobility is limited to some extent making dodging enemy
                  attacks harder,
                </li>
                <li>
                  Some bosses have unusual hitboxes which can make striking them
                  while airborne a challenge, resulting in damage losses.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faCookieBite}
                  width="30"
                  className="bronze"
                />
              </p>
            </div>
          </div>
        </div>
        <p>Reroll value explanation:</p>
        <ul>
          <li>
            <FontAwesomeIcon icon={faTrophy} width="18" className="gold" /> -
            The best you can go for,
          </li>
          <li>
            <FontAwesomeIcon icon={faTrophy} width="18" className="silver" /> -
            The runner-up who won't disappoint you,
          </li>
          <li>
            <FontAwesomeIcon icon={faTrophy} width="18" className="bronze" /> -
            A versatile character with something unique that makes it stand out,
          </li>
          <li>
            <FontAwesomeIcon icon={faCookieBite} width="18" /> - While they
            aren't strong enough to stand on the podium, they still have some
            good things about them that make them worth using - so they deserve
            a cookie reward!
          </li>
        </ul>
        <SectionHeader title="TL;DR" />
        <ul>
          <li>
            If you didn't get Verina from a random pull or Beginner Convene (50
            pulls), pick her from the Selected Convene (80 pulls),
          </li>
          <li>
            If you did get Verina from a random pull or Beginner Convene, pick
            either Calcharo or Encore as your target in the Selector Convene (80
            pulls) if you care about ToA and if you want to have an easy time in
            the overworld, pick Jianxin instead.
          </li>
        </ul>
        {/* <SectionHeader title="4★ Characters" />
        <p>
          On top of the 5★ characters who are your main reroll targets, there's
          actually quite a lot of strong 4★ ones that you might want to look out
          for. But first, here's the list of characters you will obtain for
          free:
        </p>
        <div className="employee-container for-nikke">
          <WWCharacter mode="icon" slug="chixia" enablePopover showLabel />
          <WWCharacter mode="icon" slug="baizhi" enablePopover showLabel />
          <WWCharacter mode="icon" slug="yangyang" enablePopover showLabel />
          <WWCharacter mode="icon" slug="sanhua" enablePopover showLabel />
          <WWCharacter mode="icon" slug="yuanwu" enablePopover showLabel />
        </div>
        <p>And you can check our other guide to learn</p> */}
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesRer;

export const Head: React.FC = () => (
  <Seo
    title="Reroll Guide | Wuthering Waves | Prydwen Institute"
    description="How to reroll in Wuthering Waves and who is the best character to go for!"
    game="ww"
  />
);
